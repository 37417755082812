@keyframes reveal{
  100%{
    opacity: 1;
    transform: skewY(0deg);
  }
}
@keyframes slideup{
  100%{
    opacity: 1;
    transform: translateY(0) scaleX(1);
  }
}
@keyframes expand{
  100%{
    opacity: 1;
    transform: scaleX(1);
  }
}

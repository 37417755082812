.header{
  position: relative;
  display: flex;
  justify-content: space-between;
  &__navigation{
    font-size: 1.8rem;
    text-align: right;
    a{
      display: block;
      margin-bottom: 1rem;
      &::before, &::after{
        display: inline-block;
        opacity: 0;
        transition: transform .3s, opacity .2s;
      }
      &::before{
        content: '[';
        margin-right: 1rem;
        transform: translate(2rem);
      }
      &::after{
        content: ']';
        margin-left: 1rem;
        transform: translate(-2rem);
      }
      &:hover, &:focus{
        &::before, &::after{
          opacity: 1;
          transform: translate(0);
        }
      }
      @include breakpoint(720px) {
        display: inline-block;
      }
    }
  }
  &__themes{
    position: absolute;
    top: -3rem;
    right: 1vw;
    opacity: 1 !important;
    cursor: pointer;
    fill: var(--theme-switcher);
    @include breakpoint(470px) {
      top: -4rem;
      right: -4rem;
    }
  }
}

/*---------------------------------*\
  Utility Rules (u- namespace)
\*---------------------------------*/
.u{
  &-ellipsis-line{
    @include ellipsis-line(100%);
  }
  &-middle-block{
    display: block;
    margin: 0 auto;
  }
  &-fullwidth{ width: 100%; }
  &-inline-block{ display: inline-block; }
  &-invisible{ visibility: hidden; }
  &-ghost{ display: none; }
  &-ir{
    background-color: transparent;
    border: 0;
    overflow: hidden;
    /* IE 6/7 fallback */
    *text-indent: -9999px;
    &::before{
      content: "";
      display: block;
      width: 0;
      height: 150%;
    }
  }
  &-inherit-color.u-inherit-color{
    color: inherit;
    text-decoration: none;
  }
  &-txtcenter{ text-align: center }
  &-txtleft{ text-align: left }
  &-txtright{ text-align: right }
  &-fr{ float: right }
  &-fl{ float: left }
  &-visuallyhidden{
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    &.focusable:active, &.focusable:focus{
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: static;
      width: auto;
    }
  }
  &-pad10{
    padding: 10px;
  }
  &-nopad.u-nopad{
    padding: 0;
  }
}
/* Extra hack utilities */
.clearfix{
  &:after, &:before{
    content: " ";
    display: table;
  }
  &:after{
    clear: both;
  }
}

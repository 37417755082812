.comments{
  border-top: solid thin var(--comments-border);
  text-align: center;
  overflow: hidden;
  &__trigger{
    appearance: none;
    display: block;
    position: relative;
    z-index: 100;
    width: 100%;
    height: 70px;
    margin: 0;
    padding: 2.4rem 0;
    background: none;
    border: 0;
    color: inherit;
    line-height: 1.2;
    outline: none;
    cursor: pointer;
  }
  &__content{
    border-bottom: solid thin var(--comments-border);
    transform: translateY(-100%);
    transition: transform 1s cubic-bezier(.5,0,0,.99);
    overflow: hidden;
  }
  &__thread{
    transform: translateY(120%);
    transition: inherit;
  }
  &__animate{
    transform: none;
  }
}

$site-width: 800px !default;
$color-brand-1: #2c4f73;
$color-brand-2: #b30950;
$color-brand-3: #192740;
$color-brand-4: #90136f;
$background-primary: none;
$background-secondary: #222;
$color-primary-text: #222;
$color-secondary-text: #eee;
$inputs-border-radius: 0;
$font--primary: system-ui, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !default;
$font--secondary: 'Roboto', sans-serif !default;


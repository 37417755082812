/*--------------------------------*\
  Global Rules/Namespace
\*--------------------------------*/
*{
  box-sizing: border-box;
  &:focus{
    outline-color: #555;
    outline-width: thin;
  }
}
:root{
  @include light-theme-config;
}
.theme-active{
  @include dark-theme-config;
  img {
    filter: brightness(.8) contrast(1.2);
  }
}
@media (prefers-color-scheme: dark){
  :root{
    @include dark-theme-config;
  }
  img {
    filter: brightness(.8) contrast(1.2);
  }
  .theme-active{
    @include light-theme-config;
    img{
      filter: none;
    }
  }
}
::selection{
  background: #798095;
}
html{
  font-size: 62.5%;
  width: 100%;
  overflow-x: hidden;
}
body{
  padding: 5rem 3rem;
  @include breakpoint(471px){
    padding: 6rem;
  }
  font-family: $font--primary;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  background: var(--background-color);
  color: var(--foreground-color);
}
blockquote{
  position: relative;
  margin: 0 1rem;
  padding: 1rem 4rem;
  background: rgba(black, .05);
  border-left: solid thick #444;
  font-weight: 300;
  &::before{
    content: '“';
    position: absolute;
    top: -2rem;
    left: 1rem;
    font-size: 8rem;
    color: var(--blockquote-quote);
  }
}
cite{
  display: block;
  &::before{
    content: '— ';
  }
}
a{
  color: var(--link-color);
  text-decoration: none;
  &:not(.no-uline){
    padding: 4px 0 4px;
    background-image: linear-gradient(to bottom, transparent 20%, currentColor 21%);
    background-size: 0 .3rem;
    background-position: 0 100%;
    background-repeat: no-repeat;
    transition: background-size .3s cubic-bezier(.5,0,.5,1) .1s;
    &:hover, &:focus{
      background-size: 100% .3rem;
    }
  }
}
input{
  font-family: inherit;
}

dfn, abbr{
  border-bottom: dashed thin var(--abbr-border-color);
  text-decoration: none;
}
b, strong{
  font-weight: 600;
}
em{
  font-style: italic;
  font-weight: 300;
}

.footnotes {
  font-size: 1.4rem;
}

code {
  padding: 1px 5px;
  border-radius: $inputs-border-radius;
  background-color: rgba(#000, .3);
}

pre {
  padding: 0 12px;
  overflow: auto;
  font-size: 1.6rem;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
    padding: 0;
    background-color: transparent;
  }
  &:not(.line-pre){
    padding: 0 1.2rem;
    line-height: 30px;
    font-size: .8em;
  }
}
.wrap{
  width: 100%;
  max-width: $site-width;
  margin: 0 auto;
  padding: 0 10px;
}
.icon{
  fill: currentColor;
  vertical-align: middle;
  width: 2rem;
  height: 2rem;
}
.logo{
  width: 5rem;
  height: 5rem;
  fill: var(--logo-fill);
}

/**
 * Typography:
 *
 *   @example
 *   <h1>Heading 1</h1>
 *   <h2>Heading 2</h2>
 *   <h3>Heading 3</h3>
 *   <h4>Heading 4</h4>
 *   <h5>Heading 5</h5>
 *   <h6>Heading 6</h6>
 *   <p>A paragraph with some text in it</p>
 */
h1,h2,h3,h4,h5,h6{
  line-height: 1.3;
  font-weight: 500;
}
h1,h2,h3,h4,h5,h6,p{
  margin: 1.5rem 0 1rem;
}
h1{
  font-size: 2.8rem;
  @include breakpoint(640px){
    font-size: 4.8rem;
  }
}
h2{
  font-size: 2.6rem;
  @include breakpoint(640px){
    font-size: 3.6rem;
  }
}
h3{
  font-size: 2.4rem;
  @include breakpoint(640px){
    font-size: 3rem;
  }
}
h4{
  font-size: 2.2rem;
  @include breakpoint(640px){
    font-size: 2.4rem;
  }
}
h5{
  font-size: 2rem;
}
h6{
  font-size: 1.8rem;
}
small{
  font-size: 1.3rem;
}
.light-type{
  font-weight: 300;
}


// Default divider
hr{
  margin: 5rem auto;
  padding: 0;
  border: 0;
  color: var(--divider-color);
  text-align: center;
  overflow: inherit;
  &::after{
    content: '✯ ✯ ✯';
    display: inline-block;
    position: relative;
    top: -1rem;
    font-size: 2rem;
  }
}
// Use on HRs
.divider{
  border: 0;
  // Gradient Full Page
  &--one{
    height: 1px;
    background: #333;
    background-image: linear-gradient(to right, #ccc, #333, #ccc);
  }
  // Gradient Fades
  &--two{
    height: 1px;
    background-image: linear-gradient(to right, rgba(#000, 0), rgba(#000, .75), rgba(#000, 0));
  }
  // Dashed
  &--three{
    border-bottom: dashed thin #ccc;
    background: #999;
  }
  // Drop Shadow
  &--four{
    height: 12px;
    box-shadow: inset 0 12px 12px -12px rgba(#000, .5);
  }
  // Cloud
  &--five{
    height: 0;
    box-shadow: 0 0 10px 1px #000;
    &::after{
      content: '\00a0';
    }
  }
  // Slim
  &--six{
    height: 0;
    border-top: solid thin rgba(#000, .1);
    border-bottom: solid thin rgba(#fff, .3);
  }
  // Snake flair edges
  &--seven{
    height: 30px;
    border-style: solid;
    border-color: black;
    border-width: 1px 0 0 0;
    border-radius: 20px;
    &::before{
      content: '';
      display: block;
      height: 30px;
      margin-top: -31px;
      border-style: solid;
      border-color: black;
      border-width: 0 0 1px 0;
      border-radius: 20px;
    }
  }
  // Double line + Glyph
  &--eight{
    padding: 0;
    border-top: medium double #333;
    color: #333;
    text-align: center;
    &::after{
      content: '§';
      display: inline-block;
      position: relative;
      top: -0.7em;
      font-size: 1.5em;
      padding: 0 .25em;
      background: #fff;
    }
  }
}

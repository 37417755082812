/**
 * Pagination:
 * `.paginate` - Pagination can be used with lists that should paginate
 *
 *   @example
 *   <ul class="paginate" role="menubar" aria-label="pagination">
 *     <li aria-disabled="true" class="is-disabled is-pagination-disabled"><a href="#">Previous</a></li>
 *     <li><a href="#">1</a></li>
 *     <li><a href="#">2</a></li>
 *     <li class="is-pagination-current"><a href="#">3</a></li>
 *     <li><a href="#">4</a></li>
 *     <li><a href="#">5</a></li>
 *     <li><a href="#">Next</a></li>
 *   </ul>
 */
.paginate{
  display: inline-block;
  &__previous, &__next{
    display: inline-block;
    width: 2rem;
    padding: 0;
    color: #888;
    text-align: center;
    font-size: 4rem;
    line-height: .85;
  }
  span.paginate__previous, span.paginate__next{
    color: var(--pagination-span);
  }
  a.paginate__previous, a.paginate__next{
    color: var(--pagination-link);
  }
}

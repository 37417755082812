figcaption{
  padding: 10px;
  font-size: 1.2rem;
}
img{
  max-width: 100%;
}
figure{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  > img{
    display: block;
    margin: 0 auto;
  }
}
.figure--multiple{
  width: 100%;
  display: flex;
  justify-content: center;
  div{
    flex: 1;
    margin: 0 .2rem;
  }
}
.image{
  &--wide{
    width: 100%;
  }
  &--rounded{
    border-radius: 50%;
  }
  &--curvy{
    border-radius: 4px;
  }
  &--thumbnail{
    width: 50px;
    height: 50px;
  }
  &--medium{
    width: 90px;
    height: 90px;
  }
  &--large{
    width: 130px;
    height: 130px;
  }
  &--large-x{
    width: 180px;
    height: 180px;
  }
}
.video{
  position: relative;
  width: calc(100% + 30px);
  height: 0;
  margin-left: -15px;
  padding-bottom: 56.25%;
  padding-top: 30px;
  overflow: hidden;
  iframe, object, embed{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}
.figure--edgeleft{
  @media(min-width: 1020px) {
    display: inline;
    margin: 0;
    float: left;
    clear: both;
    margin: 0 2rem 0 -10rem;
    img{
      max-width: 500px;
    }
  }
}
.figure--fullwidth{
  margin-bottom: 20px;
  > .image, > .video{
    margin: 20px calc(50% - 50vw);
    width: 100vw;
    max-height: 650px;
    max-width: 100vw;
    object-fit: cover;
    object-position: top;
  }
}

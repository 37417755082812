// Mixins
@mixin light-theme-config {
  --background-color: #fff;
  --foreground-color: #222;
  --link-color: rgba(0, 0, 0, .7);
  --logo-fill: #000;
  --abbr-border-color: #222;
  --blockquote-quote: #333;
  --scroll-progress-bg: #000;
  --scroll-progress-shadow: rgba(255, 255, 255, .6);
  --comments-border: hsl(0 100% 0% / .2);
  --theme-switcher: #000;
  --portfolio-links-color: #000;
  --divider-color: #333;
  --form-button-bg: #000;
  --form-button-border: #000;
  --form-button-text: #fff;
  --form-placeholder-text: rgba(0,0,0, .3);
  --pagination-link: rgba(0, 0, 0, .6);
  --pagination-span: rgba(0, 0, 0, .2);
}
@mixin dark-theme-config {
  --background-color: #222;
  --foreground-color: #e2e2e2;
  --link-color: rgba(255, 255, 255, .7);
  --logo-fill: #fff;
  --abbr-border-color: #ccc;
  --blockquote-quote: #ddd;
  --scroll-progress-bg: #fff;
  --scroll-progress-shadow: rgba(0, 0, 0, .6);
  --comments-border: hsl(0 100% 100% / .2);
  --theme-switcher: #fff;
  --portfolio-links-color: #eee;
  --divider-color: #ddd;
  --form-button-bg: #fff;
  --form-button-border: #fff;
  --form-button-text: #000;
  --form-placeholder-text: rgba(255,255,255, .7);
  --pagination-link: rgba(255, 255, 255, .6);
  --pagination-span: rgba(255, 255, 255, .2);
}

// Makes Ellipsis on Single Line
@mixin ellipsis-line($width){
  width: $width;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// Gap with fallback for Safari
@mixin gap($space) {
  gap: $space;
  @media not all and (min-resolution:.001dpcm){
    @supports (-webkit-appearance:none) {
      margin-bottom: -$space;
      > *{
        padding-bottom: $space;
      }
    }
  }
}

// Gradients
@mixin gradient-dark-linear{
  background-image: -webkit-linear-gradient(rgba(0,0,0,.25), rgba(0,0,0,0));
  background-image: -o-linear-gradient(rgba(0,0,0,.25), rgba(0,0,0,0));
  background-image: linear-gradient(rgba(0,0,0,.25), rgba(0,0,0,0));
}
@mixin gradient-light-linear{
  background-image: -webkit-linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,0));
  background-image: -o-linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,0));
  background-image: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,0));
}
@mixin gradient-dark-radial{
  background-image: radial-gradient(center 0, circle farthest-corner, rgba(0,0,0,0.15), rgba(0,0,0,0));
}
@mixin gradient-light-radial{
  background-image: radial-gradient(center 0, circle farthest-corner, rgba(255,255,255,0.4), rgba(255,255,255,0));
}
// Responsive Breakpoints
@mixin breakpoint($value){
  @media only screen and (min-width: $value){
    @content;
  }
}
// Tinted Hero Images
@mixin tinted-bg($overlay-color, $alpha-level, $image, $size: normal){
  @if function-exists(image-url){
    background: linear-gradient(rgba($overlay-color, $alpha-level), rgba($overlay-color, $alpha-level)), image-url($image);
  } @else {
    background: linear-gradient(rgba($overlay-color, $alpha-level), rgba($overlay-color, $alpha-level)), url($image);
  }
  background-size: $size;
}


/*  Print Styles */
@media print{
  *{
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a{
    text-decoration: underline;
    &:visited{ text-decoration: underline; }
  }
  abbr[title]:after{
    content: " (" attr(title) ")";
  }
  pre, blockquote{
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead{ display: table-header-group; }
  tr{ page-break-inside: avoid; }
  img{
    page-break-inside: avoid;
    max-width: 100% !important;
  }
  @page{ margin: 0.5cm; }

  p, h2, h3{
    orphans: 3;
    widows: 3;
  }
  h2, h3{ page-break-after: avoid; }

  .footer, .paginate{
    display: none;
  }
}

.portfolio{
  &__intro{
    margin: 5rem 0;
    h1{
      font-weight: 700;
      font-size: 4rem;
    }
    @include breakpoint(720px) {
      margin: 10rem 0;
      h1{
        font-size: 7rem;
      }
    }
  }
  &__skills{
    font-size: 2rem;
  }
  &__works{
    display: grid;
    margin: 5rem 0;
    grid-template-columns: 1fr;
    @include breakpoint(1024px){
      grid-template-columns: 1fr 3fr;
    }
  }
  &__showcase{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5rem 10rem;
    @include breakpoint(680px){
      grid-template-rows: repeat(2, auto);
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__item{
    opacity: 0;
    transform: skewX(-15deg);
    animation: reveal .3s cubic-bezier(.5,0,.5,1) forwards;
    &:nth-of-type(2) {
      animation-delay: .2s;
    }
    &:nth-of-type(3) {
      animation-delay: .2s;
    }
    &:nth-of-type(4) {
      animation-delay: .3s;
    }
    img{
      width: 100%;
    }
  }
  &__links{
    &:last-of-type{
      order: 5;
    }
    &:nth-of-type(5){
      order: 6;
    }
    @include breakpoint(680px){
      &:nth-of-type(5){
        order: 5;
      }
      &:last-of-type{
        order: 6;
      }
    }
    h6 a{
      display: inline-block;
      position: relative;
      transition: transform .3s ease-in-out .1s;
      &::after{
        content: '';
        position: absolute;
        top: .65em;
        opacity: .2;
        width: 7rem;
        margin-left: 1rem;
        border-bottom: solid thin var(--portfolio-links-color);
        transform: scaleX(.71);
        transition: opacity .2s ease .1s, transform .3s ease-in-out .1s;
      }
      &:hover, &:focus{
        transform: translate(-1rem);
        &::after{
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
  &__nav{
    a{
      opacity: .4;
      &:hover{
        opacity: 1;
      }
    }
  }
}

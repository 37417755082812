.form{
  margin: 1rem 0;
  display: grid;
  -ms-grid-columns: 22rem 1fr;
  grid-template-columns: 22rem 10rem;
  * { outline: none; }
  &__input{
    width: 100%;
    height: 4rem;
    padding: .5rem 1rem;
    border: solid thin currentColor;
    border-radius: 4px 0 0 4px;
    background: transparent;
  }
  &__input:-webkit-autofill,
  &__input:-webkit-autofill:hover, 
  &__input:-webkit-autofill:focus, 
  &__input:-webkit-autofill:active
  {
  -webkit-box-shadow: 0 0 0 30px var(--background-color) inset !important;
  }
}
.form__button{
  vertical-align: top;
  height: 4rem;
  padding: .5rem 1rem;
  border: solid thin var(--form-button-border);
  border-left: none;
  border-radius: 0 4px 4px 0;
  background: transparent;
  transition: background-color .3s ease-in-out, color .3s ease;
  color: inherit;
  font-size: 1.4rem;
  line-height: 1;
  &:hover{
    background-color: var(--form-button-border);
    color: var(--form-button-text);
  }
}
::-webkit-input-placeholder{
  color: var(--form-placeholder-text);
}
::placeholder{
  color: var(--form-placeholder-text);
}
::-moz-placeholder{
  color: var(--form-placeholder-text);
}
:-ms-input-placeholder{
  color: var(--form-placeholder-text);
}

.footer{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  align-items: end;
  margin: 10rem 0 5rem;
  font-family: monospace;
  &__heading{
    margin-top: 3rem;
  }
  &__social{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
  }
  &__right{
    order: 1;
    @include breakpoint(720px){
      order: 2;
      text-align: right;
    }
  }
  &__left{
    order: 2;
    @include breakpoint(720px){
      order: 1;
    }
    p{
      margin: 0;
    }
  }
}

.post{
  &__header{
    margin: 10rem 0;
    text-align: center;
    opacity: 0;
    transform: scaleX(0.8);
    transform-origin: center bottom;
    animation: expand .2s cubic-bezier(.5,0,.5,1) forwards;
    p{
      font-size: 1.8rem;
      font-weight: 300;
    }
  }
  &__title{
    margin-bottom: 3rem;
    font-weight: 700;
    font-size: 4.5rem;
    @media(min-width: 720px) {
      font-size: 8rem;
    }
  }
  &__footer, &__actions{
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  &__actions{
    margin: 5rem 0 2rem;
    font-size: 1.4rem;
    > *{
      display: flex;
      align-items: center;
    }
    > :last-child{
      justify-content: flex-end;
    }
  }
  &__sharelinks a{
    margin-left: 1rem;
  }
  main{
    font-size: 1.8rem;
    transform: translateY(5rem) scaleX(0.9);
    transform-origin: bottom;
    animation: slideup .3s cubic-bezier(.5,0,.5,1) forwards;
  }
  article p{
    margin: 3rem 0;
  }
  a{
    color: #517dd4;
  }
}
.scroll-progress{
  --scale: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  width: 100%;
  height: .5rem;
  background: var(--scroll-progress-bg);
  box-shadow: 0 0 .4rem var(--scroll-progress-shadow);
  transform: scaleX(var(--scale));
  transform-origin: left;
  transition: transform .2s linear;
  will-change: transform;
}

table{
  width: 100%;
  margin: 20px 0;
  box-shadow: 0 0 1px rgba(#000, .2);
}
th, td{
  padding: 10px;
  text-align: left;
}
tr{
  background: rgba(#fff, .1);
  &:nth-of-type(even){
    background: rgba(#000, .1);
  }
}
thead tr{
  background: rgba(#000, .1);
}

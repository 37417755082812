.tags{
  display: flex;
  @include gap(10px);
  a{
    display: inline-block;
    position: relative;
    margin-bottom: .5rem;
    padding: .2rem .6rem;
    border-radius: 4px;
    background: #444;
    color: #fff !important;
    &:hover{
      background: #000;
    }
  }
}
